import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footer/Footer.js";
import { dashRoutes, adminRoutes } from "routes.js";
import { ROLE_KEY } from "utils/constants/AuthConfig.js";
import {
  adminUserRole,
  // HUB_LOGIN_URL,
  // DOMAIN_BASE_URL,
} from "utils/constants/index.js";
import { getActiveRoute } from "utils/helpers/index.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";

// login bg
import login from "assets/img/login.jpeg";

const useStyles = makeStyles(styles);

export default function Pages(props) {
  const { ...rest } = props;

  // Auth0 Data
  const { isAuthenticated, isLoading, user: authUser } = useAuth0();

  // ref for the wrapper div
  const wrapper = React.createRef();
  // styles
  const classes = useStyles();
  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        // window.location.href = HUB_LOGIN_URL + DOMAIN_BASE_URL;
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  if (isLoading === true) return null;

  let routes =
    authUser && authUser[ROLE_KEY] == adminUserRole ? adminRoutes : dashRoutes;

  return (
    <div>
      <AuthNavbar brandText={getActiveRoute(routes)} {...rest} />
      <div className={classes.wrapper} ref={wrapper}>
        <div
          className={classes.fullPage}
          style={{ backgroundImage: "url(" + login + ")" }}
        >
          <Switch>
            {isAuthenticated && <Redirect to="/admin/create-email-template" />}
            {getRoutes(routes)}
            <Redirect from="/auth" to="/auth/login" />
          </Switch>
          <Footer white />
        </div>
      </div>
    </div>
  );
}
