import html2canvas from "html2canvas";
import { USER_METADATA } from "utils/constants/AuthConfig";

// core components
import { emailPlatformValue } from "utils/constants/index.js";

// get active route
export const getActiveRoute = (routes) => {
  let activeRoute = "";
  for (let i = 0; i < routes.length; i++) {
    if (routes[i].collapse) {
      let collapseActiveRoute = getActiveRoute(routes[i].views);
      if (collapseActiveRoute !== activeRoute) {
        return collapseActiveRoute;
      }
    } else {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].name;
      }
    }
  }
  return activeRoute;
};

// get active route
export const getIsAgency = (authUser) => {
  const isAgency = authUser[USER_METADATA]?.agency;
  if (isAgency) {
    return isAgency;
  } else {
    return false;
  }
};

// generate HTML email thumbnail
export const captureElement = async (htmlContent, randonNumber) => {
  const iframe = document.createElement("iframe");
  iframe.id = `thumbnail_${randonNumber}`;
  iframe.className = "email_thumbnail";
  iframe.style.width = "100%";
  iframe.style.height = "100%";
  iframe.style.visibility = "hidden";
  iframe.style.overflow = "hidden";
  iframe.style.position = "absolute";
  iframe.style.top = "0";
  document.body.append(iframe);
  iframe.contentWindow.document.open();
  iframe.contentWindow.document.write(htmlContent);
  iframe.contentWindow.document.close();

  const canvas = await html2canvas(iframe.contentWindow.document.body, {
    useCORS: true, //to enable cross origin params
  });

  const imgDataUrl = canvas.toDataURL("image/png"); // Convert canvas into image
  if (imgDataUrl && document.getElementById(`prev${randonNumber}`)) {
    document.getElementById(`prev${randonNumber}`).src = imgDataUrl; // append into image tag
  }

  // Delete iFrame after image created
  setTimeout(() => {
    document
      .querySelectorAll(".email_thumbnail")
      .forEach((elem) => elem.remove());
  }, 1500);
};

// store hero-image to localStorage, if present
export const fetchHeroImg = (htmlTemplate) => {
  // parse HTML string to dom
  const dom = new DOMParser().parseFromString(htmlTemplate, "text/html");

  // store heroimg in local storage
  let heroImg = dom.documentElement.querySelector('img[alt="hero_image"]');
  // replace hero-img src
  if (heroImg) {
    const heroImgData = {
      heroImg_name: heroImg.src.substring(heroImg.src.lastIndexOf("/") + 1),
      binaryHeroImg: heroImg.src,
    };

    localStorage.setItem("heroImg_data", JSON.stringify(heroImgData));
  } else {
    localStorage.removeItem("heroImg_data");
  }
};

const replaceData = (clientDetails, emailTemplate) => {
  // client details
  const {
    clientName,
    acronym,
    organizationName,
    organizationNicName,
    communityName,
    signatureName,
    signatureTitle,
    dollarAmount,
    mealsAmount,
    defaultAlgorithm,
    clientColor,
    emailPlatform,
    footerHtml,
  } = clientDetails;

  emailTemplate = emailTemplate
    .replace(footerHtml && /\{{FOOTER_HTML}}/g, footerHtml)
    .replace(clientName && /\{{CLIENT_NAME}}/g, clientName)
    .replace(acronym && /\{{ACRONYM}}/g, acronym)
    .replace(organizationName && /\{{ORGANIZATION_NAME}}/g, organizationName)
    .replace(
      organizationNicName && /\{{ORGANIZATION_NIC_NAME}}/g,
      organizationNicName
    )
    .replace(communityName && /\{{COMMUNITY_NAME}}/g, communityName)
    .replace(signatureName && /\{{SIGNATURE_NAME}}/g, signatureName)
    .replace(signatureTitle && /\{{SIGNATURE_TITLE}}/g, signatureTitle)
    .replace(dollarAmount && /\{{DOLLAR_AMOUNT}}/g, dollarAmount)
    .replace(mealsAmount && /\{{MEALS_AMOUNT}}/g, mealsAmount)
    .replace(defaultAlgorithm && /\{{DEFAULT_ALGORITHM}}/g, defaultAlgorithm)
    .replace(clientColor && /#FF69B4|#ff69b4/g, clientColor)
    .replace(
      emailPlatform && /\{{FIRST_NAME}}/g,
      emailPlatform == emailPlatformValue.mailChimp.key
        ? emailPlatformValue.mailChimp.value
        : emailPlatform == emailPlatformValue.constantContact.key
        ? emailPlatformValue.constantContact.value
        : emailPlatform == emailPlatformValue.luminateOnline.key
        ? emailPlatformValue.luminateOnline.value
        : emailPlatform == emailPlatformValue.other.key
        ? emailPlatformValue.other.value
        : ""
    );

  const dom = new DOMParser().parseFromString(emailTemplate, "text/html");

  // remove background-color from body & table tag
  dom.body?.style.removeProperty("background-color");
  const firstTableElem = dom.getElementsByTagName("table")[0]; // initial table tag element
  firstTableElem?.style.removeProperty("background-color");

  // replace header donate button block
  let headerButton = dom.documentElement.querySelectorAll(".button_block");
  if (headerButton?.length) {
    for (let i = 0; i < headerButton.length; i++) {
      headerButton[i].innerHTML = `<tbody>
        <tr>
          <td align="${
            i == 0 ? "right" : "center"
          }" class="pad" style="padding-bottom:15px;padding-right:20px;padding-top:15px;">
            <table border="0" cellpadding="0" cellspacing="0" width="149"
              style="height:44px;width:149px;min-width: 149px" bgcolor="${clientColor}">
              <tr>
                <td align="center" height="44" style="font-family:Arial, Helvetica, sans-serif;font-size:16px;line-height:1;">
                  <strong>
                    <a href="{{DEFAULT_DF_URL}}"
                      style="text-decoration:none;color:#ffffff;background-color:${clientColor};height:44px;line-height:44px;display: block;font-size: 16px"
                      target="_blank">${headerButton[i].textContent}</a>
                  </strong>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </tbody>`;
    }
  }

  // serialize html dom
  emailTemplate = dom.documentElement.outerHTML;

  // doc-type update
  const doctypeStr = "<!DOCTYPE html>";
  const newDoctype = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">`;
  emailTemplate = emailTemplate.match(doctypeStr)
    ? emailTemplate.replace(doctypeStr, newDoctype)
    : newDoctype + emailTemplate;

  return emailTemplate;
};

// replace placeholders with client data
export const replacePlaceholders = (singleClientData, htmlTemplate) => {
  // client details
  const {
    clientName,
    clientWebsiteUrl,
    clientLogoUrl,
    signatureFileUrl,
    defaultDfUrl,
  } = singleClientData;

  // replace clients signature
  htmlTemplate = signatureFileUrl
    ? htmlTemplate.replace(
        /\{{SIGNATURE_FILE_URL}}/g,
        `<img width="130" height="65" src=${signatureFileUrl} />`
      )
    : htmlTemplate;

  // replace html template placeholders
  htmlTemplate = replaceData(singleClientData, htmlTemplate);

  const dom = new DOMParser().parseFromString(htmlTemplate, "text/html");

  let logoUrl = dom.documentElement.querySelectorAll(
    'img[alt="{{CLIENT_LOGO_URL}}"]'
  );
  // replace all client logo src and alt
  if (clientLogoUrl && logoUrl && logoUrl.length) {
    for (let j = 0; j < logoUrl.length; j++) {
      logoUrl[j].setAttribute("alt", `${clientName}_logo`);
      logoUrl[j].setAttribute("title", `${clientName}_logo`);
      logoUrl[j].setAttribute("src", clientLogoUrl);
    }
  }

  // serialize html dom
  htmlTemplate = dom.documentElement.outerHTML;

  // find all quoted text from html template
  const qoutedData = htmlTemplate.match(/(["']).*?\1/g);
  for (let i = 0; i < qoutedData.length; i++) {
    // replace all df url
    if (qoutedData[i].toLowerCase().includes("default_df_url")) {
      htmlTemplate = htmlTemplate.replace(qoutedData[i], `"${defaultDfUrl}"`);
    }

    // replace all client website url
    if (qoutedData[i].toLowerCase().includes("client_website_url")) {
      htmlTemplate = htmlTemplate.replace(
        qoutedData[i],
        `"${clientWebsiteUrl}"`
      );
    }
  }

  return htmlTemplate;
};

// replace client email placeholders
export const replaceClientEmailPlaceholders = (data) => {
  const { emaildata, clientDetails } = data;
  let { htmlTemplate } = data;
  const email = JSON.parse(emaildata);

  // client details
  const {
    clientName,
    clientWebsiteUrl,
    clientLogoUrl,
    signatureFileUrl,
    defaultDfUrl,
    utmParams,
    urlParameters,
  } = JSON.parse(clientDetails);

  const utm_Param = utmParams ? JSON.parse(utmParams) : {};
  const url_Param = urlParameters ? JSON.parse(urlParameters) : {};
  let utm_ParamStr = "";
  let url_ParamStr = "";
  if (Object.keys(utm_Param).length) {
    for (let value of Object.keys(utm_Param)) {
      if (utm_Param[value].checked) {
        utm_ParamStr += `${value}=${utm_Param[value].value}&`;
      }
    }
  }
  if (Object.keys(url_Param).length) {
    for (let value of Object.keys(url_Param)) {
      if (url_Param[value].checked) {
        url_ParamStr += `${url_Param[value].label}=${
          url_Param[value]?.value ? url_Param[value]?.value : ""
        }&`;
      }
    }
  }
  // UTM & URL parameters
  let constructedParams = utm_ParamStr + url_ParamStr;
  if (constructedParams.charAt(constructedParams.length - 1) == "&") {
    constructedParams = constructedParams.substr(
      0,
      constructedParams.length - 1
    );
  }

  // replace clients signature
  htmlTemplate = htmlTemplate
    .replace(
      /\{{SIGNATURE_FILE_URL}}/g,
      `<img width="130" height="65" src=${signatureFileUrl} />`
    )
    .replace(/\{{PREVIEW_TEXT}}/g, email.preview_text);

  // replace html template placeholders
  htmlTemplate = replaceData(JSON.parse(clientDetails), htmlTemplate);

  const dom = new DOMParser().parseFromString(htmlTemplate, "text/html");

  let logoUrl = dom.documentElement.querySelectorAll(
    'img[alt="{{CLIENT_LOGO_URL}}"]'
  );
  // replace all client logo src and alt
  if (logoUrl?.length) {
    for (let j = 0; j < logoUrl.length; j++) {
      logoUrl[j].setAttribute("alt", `${clientName}_logo`);
      logoUrl[j].setAttribute("title", `${clientName}_logo`);
      logoUrl[j].setAttribute("src", clientLogoUrl);
    }
  }

  // serialize html dom
  htmlTemplate = dom.documentElement.outerHTML;

  // find all quoted text from html template
  const qoutedData = htmlTemplate.match(/(["']).*?\1/g);
  for (let i = 0; i < qoutedData.length; i++) {
    // replace all df url
    if (qoutedData[i].toLowerCase().includes("default_df_url")) {
      const dfUrl = defaultDfUrl.includes("?")
        ? `${defaultDfUrl}&${constructedParams}`
        : `${defaultDfUrl}?${constructedParams}`;
      htmlTemplate = htmlTemplate.replace(qoutedData[i], dfUrl);
    }

    // replace all client website url
    if (qoutedData[i].toLowerCase().includes("client_website_url")) {
      htmlTemplate = htmlTemplate.replace(
        qoutedData[i],
        clientWebsiteUrl.includes("?")
          ? `"${clientWebsiteUrl}&${constructedParams}"`
          : `"${clientWebsiteUrl}?${constructedParams}"`
      );
    }
  }

  return htmlTemplate;
};
