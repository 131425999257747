import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";

// material-ui icons
import Menu from "@material-ui/icons/Menu";
import Logout from "@material-ui/icons/PowerSettingsNew";

// core components
import Button from "components/CustomButtons/Button.js";
// import { HUB_LOGIN_URL } from "utils/constants";
import crypto from "crypto";

// style
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";

const useStyles = makeStyles(styles);

export default function AdminNavbar(props) {
  // auth0 methods
  const { logout, user } = useAuth0();
  // styles
  const classes = useStyles();
  // props
  const { color } = props;

  const appBarClasses = cx({
    [" " + classes[color]]: color,
  });

  useEffect(() => {
    const secretKey = process.env.REACT_APP_INTERCOM_SECRETE; // an Identity Verification secret key (web)
    const userIdentifier = user.email; // the email for your user
    const hash = crypto
      .createHmac("sha256", secretKey)
      .update(userIdentifier)
      .digest("hex");
    window.Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: "ubbjplne",
      name: user.name, // Full name
      email: user.email, // the email for your user
      created_at: user.updated_at, // Signup date as a Unix timestamp
      user_hash: hash, // HMAC using SHA-256
    });
  }, []);

  const handleLogout = () => {
    // window.location.href = HUB_LOGIN_URL + "/apps/Logout&BuildR";
    logout({ returnTo: window.location.origin + "/auth/login" });
    localStorage.clear();
    window.Intercom("shutdown");
  };

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden mdUp implementation="css">
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
        <Button
          className={classes.logoutButton}
          color="transparent"
          size="lg"
          onClick={handleLogout}
        >
          <center>
            <Logout titleAccess="Logout" />
          </center>
          <Hidden smDown implementation="css">
            <span>LOGOUT</span>
          </Hidden>
        </Button>
      </Toolbar>
    </AppBar>
  );
}

// define props type
AdminNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  brandText: PropTypes.string,
  miniActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  sidebarMinimize: PropTypes.func,
};
