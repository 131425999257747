import React from "react";
import moment from "moment";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// style
import styles from "assets/jss/material-dashboard-pro-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <GridContainer>
        <GridItem xs={7}>
          <p className={`${classes.right} ${classes.gridItem1}`}>
            &copy;{2022}
            {"-"}
            {1900 + new Date().getYear()} {"BuildR"}
          </p>
        </GridItem>
        <GridItem xs={5}>
          <p className={classes.right}>
            {`Last Updated At: 
            ${moment
              .unix(process.env.REACT_APP_BUILD_TIMESTAMP)
              .utc()
              .format("MM-DD-YYYY, h:mm:ss a")}
             UTC`}
          </p>
        </GridItem>
      </GridContainer>
    </footer>
  );
}
